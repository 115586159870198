<template>
  <div class="base-list-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput" label="是否有效">
          <el-select
            v-model="searchForm.isUse"
            placeholder="是否有效"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in isUseOptions"
              :key="index"
              :label="item.name"
              :value="item.isUse"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput" label="组织类型">
          <el-select
            v-model="searchForm.orgType"
            placeholder="组织类型"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in orgTypeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput" label="用户名">
          <el-input
            placeholder="用户名"
            v-model="searchForm.username"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="typeInput" label="真实名称">
          <el-input
            placeholder="真实名称"
            v-model="searchForm.realName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
          <el-button
            v-if="isAuth('cc:sysUser:save')"
            type="primary"
            @click="addOrUpdateHandle(null, true)"
            >新增
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      ></el-table-column>

      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        width="120"
        label="用户名"
      ></el-table-column>

      <el-table-column
        prop="realName"
        header-align="center"
        align="center"
        width="120"
        label="真实姓名"
      ></el-table-column>

      <el-table-column
        prop="orgType"
        header-align="center"
        align="center"
        width="120"
        label="组织类型"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.orgType === 0" size="small">供应商</el-tag>
          <el-tag v-else-if="scope.row.orgType === 1" size="small"
            >代理商&分销</el-tag
          >
          <el-tag v-else-if="scope.row.orgType === 2" size="small"
            >投资商</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="orgName"
        header-align="center"
        align="center"
        width="120"
        label="组织名称"
      ></el-table-column>

      <el-table-column
        prop="isUse"
        header-align="center"
        align="center"
        width="120"
        label="是否使用"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isUse" size="small" type="success">是</el-tag>
          <el-tag v-else size="small">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        header-align="center"
        align="center"
        width="180"
        label="创建时间"
      ></el-table-column>
      <el-table-column
        prop="createdBy"
        header-align="center"
        align="center"
        width="120"
        label="创建者"
      ></el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="180"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('cc:sysUser:update')"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row, true)"
            >编辑
          </el-button>
          <el-button
            v-if="isAuth('cc:sysUser:info')"
            type="text"
            size="small"
            @click="showDetails(scope.row, false)"
            >查看
          </el-button>
          <el-button
            v-if="isAuth('cc:sysUser:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './sys-user-org-add-or-update';
import { normal } from '@/mixins';

export default {
  mixins: [normal],
  data() {
    return {
      searchForm: {
        isUse: 1,
        orgType: '',
        username: '',
        realName: '',
      },
      isUseOptions: [
        {
          isUse: 1,
          name: '是',
        },
        {
          isUse: 0,
          name: '否',
        },
      ],
      orgTypeList: [
        { id: 0, name: '供应商' },
        { id: 1, name: '代理商&分销' },
        { id: 2, name: '投资商' },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/cc/sys-user/page',
        method: 'get',
        params: {
          page: this.pageIndex,
          perPage: this.pageSize,
          ...this.searchForm,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.data.items;
          this.totalPage = data.data.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 查看详情
    showDetails(data) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(data, true);
      });
    },
    // 新增 / 修改
    addOrUpdateHandle(data, addOrUpdateVisible) {
      this.addOrUpdateVisible = addOrUpdateVisible;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(data, addOrUpdateVisible);
      });
    },
    // 删除
    deleteHandle(ids) {
      this.$confirm(`确定对[id=${ids}]进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/cc/sys-user/ids/delete',
            method: 'post',
            data: { ids },
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
